import axios from 'axios';

const validateEmail = async (email: string): Promise<boolean> => {
    const expr = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/u;

    const emailIsValid = expr.test(email);
    //TODO: Wydzielic powyższe

    let testValidation: any = null;
    let validationMessage: string = '';
    let validationSuccess: boolean = true;

    if (emailIsValid) {
        try {
            const validation = await axios.post('/rejestracja/walidacja/email', {
                query: email,
                email: email
            }, {
                headers: {
                    'content-type': 'application/json'
                }
            });

            testValidation = validation.status === 200;
        } catch (error) {
            if (error.response && error.response.data.length && Array.isArray(error.response.data)) {
                validationMessage = error.response.data[0];
            }
            validationSuccess = false;
        }
    }

    return new Promise((resolve, reject) => {
        if (emailIsValid && validationSuccess) {
            resolve(testValidation);
        } else {
            reject(validationMessage);
        }
    });
};

export { validateEmail }
